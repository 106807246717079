export enum Languages {
    Spanish = "es",
    English = "en",
    German = "de",
    Portuguese = "pt",
}

export enum LocalizedLanguages {
    EnglishUS = "en-US",
    SpanishES = "es-ES",
    GermanDE = "de-DE",
    PortugueseBR = "pt-BR",
}

export const languagesStrings: Record<Languages, string> = {
    [Languages.Spanish]: "spanish",
    [Languages.English]: "english",
    [Languages.German]: "german",
    [Languages.Portuguese]: "portuguese",
};

export const getLanguage = (language: string): Languages => {
    const keys = Object.keys(Languages) as (keyof typeof Languages)[];
    for (const key of keys) {
        if (language.includes(Languages[key])) {
            return Languages[key];
        }
    }

    return Languages.English;
};

export const mapLocalizedLanguage = {
    [Languages.Spanish]: LocalizedLanguages.SpanishES,
    [Languages.English]: LocalizedLanguages.EnglishUS,
    [Languages.German]: LocalizedLanguages.GermanDE,
    [Languages.Portuguese]: LocalizedLanguages.PortugueseBR,
};
