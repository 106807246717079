export const enum Routes {
    Base = "*",
    Both = "both",
    BothGoogle = "both_google",
    DynamicBoth = "dynamic-both",
    Flow = "main-flow",
    PromoPaywall = "paywall",
    Friends = "friends",
    ChallengesRedirect = "challenges-redirect",
    Age = "age",
    Calisthenics = "calisthenics",
    Offer = "offer",
    Funnel = "funnel",
    Seniors = "seniors",
    Auth = "auth",
    SimplifiedAuth = "simplified-auth",
    ShareVideo = "share-video",
    BothWorkout = "1workout",
    Mex = "mex",
    // routes with age group selection
    AgeLift = "age-lift",
    AgeWeightLoss = "age-weightloss",
    AgeWorkout = "age-workout",
    CalisthenicsAge = "calisthenics-age",
    Gender = "gender",
    Tiktok = "tt",
    Pilates = "pilates-first",
    // route to use in tiktok bio
    Liza = "liza",
}
